<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div />
</template>
  
<script setup lang="ts">
import { onMounted, watch, PropType } from 'vue'
import {  useWaitForTransactionReceipt } from '@wagmi/vue'

const props = defineProps({
  hash: {
    type: String as PropType<`0x${string}` | undefined>,
    default: null,
  },
})

const emit = defineEmits(['on-success', 'on-fail'])

const { refetch: fetchTransactionReceipt, data: transactionReceipt } = useWaitForTransactionReceipt({ 
  hash: props.hash, 
  confirmations: 1,
})

onMounted(() => {
  fetchTransactionReceipt()
})

watch(transactionReceipt, (newReceipt) => {
  emit('on-success', newReceipt)
})
</script>
  
