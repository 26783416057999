import { ref, watch, computed, type Ref } from 'vue'
import { getChainInfo } from '#utils'
import { Connector } from '@wagmi/vue'

// address of the logged in user (not necessarily connected to the wallet)
const address: Ref<string> = ref('')

// type of the wallet connection
const type: Ref<'injected' | 'walletConnect' | 'crossMint' | null> = ref(null)

// disconnect from the current wallet if there is a connector
const disconnect = () => {
  currentConnector.value?.disconnect()
}

// chain id of the current wallet connection
const chain: Ref<number | null> = ref(null)

// current connector - set by conenct-modal
const currentConnector: Ref<Connector | null> = ref(null)

const currentChainInfo = computed(() => getChainInfo(chain?.value))

watch(currentConnector, async (newConnector) => {
  console.log('watch currentConnector', newConnector)
  if (newConnector) {
    type.value = newConnector.id as 'injected' | 'walletConnect' | 'crossMint'
  }
})

const reset = () => {
  address.value = ''
}

export default function useProviders () {
  return {
    currentConnector,
    address,
    type,
    disconnect,
    reset,
    chain,
    currentChainInfo,
  }
}
