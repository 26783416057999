import './index.css'
(window as any).global = window
import { createApp, provide, h } from 'vue'
// import { LDPlugin } from 'launchdarkly-vue-client-sdk'

import { DefaultApolloClient } from '@vue/apollo-composable'

import { VueQueryPlugin } from '@tanstack/vue-query'

import { http, createConfig, WagmiPlugin } from '@wagmi/vue'

import { type CreateConnectorFn, type Transport } from '@wagmi/core'

import { mainnet, sepolia, polygon, polygonAmoy, base, baseSepolia } from '@wagmi/vue/chains'

import { walletConnect, injected, coinbaseWallet } from '@wagmi/vue/connectors'


import { createAppClient, viemConnector } from '@farcaster/auth-client'

import { crossMint } from '#utils/crossmintConnector'

import router from './router'

import VueGtag from 'vue-gtag-next'
import offDirective from './directives/v-off'

import { apolloClient } from '#/client'
import { initHeap } from './heap'

const urlParams = new URLSearchParams(window.location.search)
for (const flag in import.meta.env) {
  if (flag.startsWith('VITE_FEATURE_')) {
    localStorage.setItem(flag, `${!!(import.meta.env[flag] && import.meta.env[flag] === 'true')}`)
    if (urlParams.get(flag) !== null) {
      localStorage.setItem(flag, `${!!(urlParams.get(flag) && urlParams.get(flag) === 'true')}`)
    }
  }
}



// if (import.meta.env.VITE_DD_ENVIRONMENT && import.meta.env.VITE_DD_CLIENT_TOKEN) {
//   datadogRum.init({
//     applicationId: 'c1f0e10e-1329-4633-b623-53c3220cc8df',
//     clientToken: `${import.meta.env.VITE_DD_CLIENT_TOKEN}`,
//     site: 'datadoghq.com',
//     service: 'remx---frontend',
//     env: `${import.meta.env.VITE_DD_ENVIRONMENT}`,
//     // Specify a version number to identify the deployed version of your application in Datadog
//     // version: '1.0.0',
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 20,
//     trackUserInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel: 'mask-user-input',
//     beforeSend: (event, context) => {
//       if (event.type === 'resource' && event.resource.type === 'fetch' && event.context && context) {
//         const requestInit = (context as any).requestInit
//         if (requestInit) {
//           event.context.requestBody = requestInit.body
//         }
//       }
//       return true
//     },
//   })
// }

const chainUrls = {
  [mainnet.id]: `https://eth-mainnet.g.alchemy.com/v2/`,
  [sepolia.id]: `https://eth-sepolia.g.alchemy.com/v2/`,
  [polygon.id]: `https://polygon-mainnet.g.alchemy.com/v2/`,
  [polygonAmoy.id]: `https://polygon-amoy.g.alchemy.com/v2/`,
  [base.id]: `https://base-mainnet.g.alchemy.com/v2/`,
  [baseSepolia.id]: `https://base-sepolia.g.alchemy.com/v2/`,
}

const transports = {} as unknown as Record<number, Transport> // <-- Paul: i dont know if this type casting is correct

for (const chain in chainUrls) {
  if (import.meta.env[`VITE_ALCHEMY_API_KEY_${chain}`]) {
    transports[chain] = http(`${chainUrls[chain]}${import.meta.env[`VITE_ALCHEMY_API_KEY_${chain}`]}`)
  }
}

const connectors: CreateConnectorFn[] = [
  coinbaseWallet({
    appName: 'Remx',
    appLogoUrl: `https://${import.meta.env.VITE_BASE_DOMAIN_NAME}/favicon.ico`,
    preference: 'smartWalletOnly',
    version: '4'

  }),
  // injected(),
  walletConnect({
    projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
    metadata: {
      name: 'Remx',
      description: 'Remx',
      url: `https://${import.meta.env.VITE_BASE_DOMAIN_NAME}`,
      icons: [`https://${import.meta.env.VITE_BASE_DOMAIN_NAME}/favicon.ico`],
    },
    qrModalOptions: {
      themeMode: 'light', themeVariables: { '--wcm-accent-color': '#000000', '--wcm-background-color': '#000000' },
      desktopWallets: [],
    },
    showQrModal: true,
  }),
]

// if (localStorage.getItem('VITE_FEATURE_CROSSMINT_LOGIN') === 'true') {
//   connectors.push(
//     crossMint({
//       environment: CrossmintEnvironment[import.meta.env.VITE_CROSSMINT_ENVIRONMENT as keyof typeof CrossmintEnvironment],
//       apiKey: import.meta.env.VITE_CROSSMINT_API_KEY,
//       chain: BlockchainTypes.POLYGON,
//     })
//   )
// }

const config = createConfig({
  chains: [mainnet, sepolia, polygon, polygonAmoy, base, baseSepolia],
  transports,
  multiInjectedProviderDiscovery: true,
  connectors,
})

import App from './App.vue'
import { BlockchainTypes, CrossmintEnvironment } from '@crossmint/connect'
import { WindowWithHeap } from '#types'

const app = createApp({
  setup () {// eslint-disable-line
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any


app.use(WagmiPlugin, { config })
app.use(VueQueryPlugin)

app.config.globalProperties.$heap = (window as WindowWithHeap)?.heap || null
initHeap()

app.directive('off', offDirective)

app.use(router)

app.use(VueGtag, {
  property: {
    id: import.meta.env.VITE_GA_ID,
    params: {
      user_id: '',
    },
  },
})

// if (import.meta.env.VITE_LAUNCH_DARKLY_ID) {
//   app.use(LDPlugin as any, { clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_ID })
// }

app.mount('#app')

//remove dummy loader
document.querySelector('#loader-container')?.classList.remove('active')
setTimeout(() => {
  document.querySelector('#loader-container')?.remove()
}, 1000)
