import { createCoinbaseWalletSDK } from '@coinbase/wallet-sdk'
import { getAddress, toHex, stringToHex } from 'viem'

export const sdk = createCoinbaseWalletSDK({
  appName: 'Remx',
  appLogoUrl: 'https://example.com/logo.png',
  appChainIds: [8453],
})

const preference = {
  options: 'smartWalletOnly',
  attribution: {
    auto: true,
  },
}

// Create provider
export const provider = sdk.getProvider(preference)

export const getAddresses = async () => {
  // Use provider
  try {
    const addresses = await provider.request({ method: 'eth_requestAccounts' })
    console.log('addresses', addresses)
    return addresses
  } catch (error) {
    console.error('Error getting addresses', error)
  }
}

export const signMessage = async (address, message) => {
  console.log(`provider`, provider)
  console.log(`message to sign`, message)

  try {
    // Convert the message to hex for Coinbase Wallet
    const hexMessage = typeof message === 'string' ? stringToHex(message) : toHex(message)
    console.log(`hex message`, hexMessage)

    const signature = await provider.request({
      method: 'personal_sign',
      params: [hexMessage, getAddress(address)]
    })

    console.log(`signature`, signature)
    return signature
  } catch (error) {
    console.error('Error signing message:', error)
    throw error
  }
}
