import { gql } from '@apollo/client/core'

export const VERIFY_WALLET = gql`
  mutation VerifyWallet($input : VerifyWalletInput!) {
    verifyWallet(input: $input) {
      accountId
      address
      type
      connectionType
      label
    }
  }
`
